<script setup lang="ts">
import { refreshToken } from "@cms/core/refresh";
import Loader from "@cms/features/Loader/LoaderView.vue";
import { provideLoader } from "@cms/features/Loader/useLoader";
import NavbarContainer from "@cms/features/Navbar/NavbarContainer.vue";
import * as Sentry from "@sentry/vue";
import { DefaultConfirmDialog, provideDefaultConfirmDialog } from "@verbleif/lib";
import { DebugTools, ThemeSetting, useAuthStore, useTheme } from "@verbleif/shared";
import { computed, getCurrentInstance, ref, watch } from "vue";
import { useRoute } from "vue-router";
import { Toaster } from "vue-sonner";

const route = useRoute();
const authStore = useAuthStore();
const loaderProvider = provideLoader();
const confirmDialogRef = ref<InstanceType<typeof DefaultConfirmDialog>>();
const app = getCurrentInstance()?.appContext.app;
const hideNavbar = computed(
  () => route.path === "/" || route.meta.hideNavbar === true,
);
const isPublicRoute = computed(
  () => route.path === "/" || route.meta.public === true,
);
const globalLoading = ref(true);

watch(
  authStore.user,
  (newUser, oldUser) => {
    if (!newUser) {
      return;
    }

    if (oldUser?.id === newUser?.id) {
      return;
    }

    Sentry.setUser(authStore.user.value);
  },
);

if (!authStore.hasToken.value && !authStore.isRemembered.value) {
  authStore.setUser(null); // Reset it to be sure.
  globalLoading.value = false;
  loaderProvider.hide();
}

watch(confirmDialogRef, (value) => {
  if (value && app) {
    provideDefaultConfirmDialog(app, value);
  }
}, { immediate: true });

if (authStore.hasToken.value || authStore.isRemembered.value) {
  refreshToken()
    .finally(() => {
      globalLoading.value = false;
      loaderProvider.hide();
    });
}

const loading = computed(() => loaderProvider.loading.value);
const { theme } = useTheme();
</script>

<template>
  <transition name="fade">
    <Loader v-if="loading || globalLoading" />
  </transition>
  <Toaster :theme="theme === ThemeSetting.DARK ? 'dark' : 'light'" rich-colors position="bottom-right" :visible-toasts="8" :expand="true" />
  <DefaultConfirmDialog ref="confirmDialogRef" />
  <template v-if="!globalLoading">
    <template v-if="!isPublicRoute && !hideNavbar">
      <NavbarContainer :class="{ 'is-visible': !hideNavbar }" class="navbar" />
      <!-- GLOBAL COMPONENTS THAT RELY ON A USER STATE SHOULD BE PLACED HERE -->
    </template>
    <div class="app-container" :class="{ 'is-login-route': !hideNavbar }">
      <router-view v-slot="{ Component }">
        <transition mode="out-in" name="fade">
          <component :is="Component" />
        </transition>
      </router-view>
      <DebugTools />
    </div>
  </template>
</template>

<style lang="scss" src="./main.scss" />
